import clsx from 'clsx';
import type { ComponentPropsWithoutRef, FC } from 'react';

import * as styles from './external-link.css';

type Props = ComponentPropsWithoutRef<'a'> & {
  href: string;
  dataTest?: string;
};

export const ExternalLink: FC<Props> = ({ children, className, dataTest, ...rest }) => {
  return (
    <a
      className={clsx([className, styles.link])}
      target={'_blank'}
      rel="noreferrer"
      data-test={dataTest}
      {...rest}
    >
      {children}
    </a>
  );
};
