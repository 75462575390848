import Image from 'next/image';
import { useRouter } from 'next/router';
import Check from 'public/check.svg';
import { FC, ReactNode, useContext, useState } from 'react';

import { useAnalytics } from '@tracking/data';
import { localeToLocalName, sortedLocalesList, useFormatMessage } from '@tracking/i18n';
import { Modal } from '@tracking/ui';

import { Button, Text } from '$src/components';
import { langStoreContext } from '$src/modules/i18n';

import * as styles from './lang-modal.css';

type LangCellProps = {
  selected: boolean;
  onSelect: () => void;
  children: ReactNode;
};

const LangCell: FC<LangCellProps> = ({ selected, onSelect, children }) => (
  <li className={styles.langCell}>
    {selected ? <Image src={Check} width={16} height={16} alt="checked" /> : <span>&nbsp;</span>}
    <button onClick={onSelect} className={styles.langButton}>
      <Text>{children}</Text>
    </button>
  </li>
);

export const LangModal: FC = () => {
  const { locale, asPath, push } = useRouter();
  const context = useContext(langStoreContext);
  const formatMessage = useFormatMessage();
  const [selectedLocale, setSelectedLocale] = useState(locale!);

  const analytics = useAnalytics();

  if (!context) {
    return null;
  }

  const availableLanguages = sortedLocalesList.map(currentLocale => (
    <LangCell
      key={currentLocale}
      selected={selectedLocale === currentLocale}
      onSelect={() => {
        setSelectedLocale(currentLocale);
        analytics.capture(`select_choose_language_"${localeToLocalName.get(selectedLocale)}"`);
      }}
    >
      {localeToLocalName.get(currentLocale)}
    </LangCell>
  ));

  const onClose = () => {
    analytics.capture('close_choose_language');
    context.setLangSelectorVisibility(false);
  };

  const onSelectLang = () => {
    analytics.capture('confirm_choose_language_via_select_button');
    push(asPath, undefined, { locale: selectedLocale });
    context.dismissLangSelection();
    onClose();
  };

  return (
    <Modal
      headerText={formatMessage('LANG_MODAL.CHOOSE_LANGUAGE')}
      onClose={onClose}
      footerContent={
        <div className={styles.footerContent}>
          <Button className={styles.selectButton} onClick={onSelectLang}>
            {formatMessage('LANG_MODAL.SELECT')}
          </Button>
        </div>
      }
    >
      <ul className={styles.langList}>{availableLanguages}</ul>
    </Modal>
  );
};
