import clsx from 'clsx';
import type { ButtonHTMLAttributes, DetailedHTMLProps, FC } from 'react';
import PulseLoader from 'react-spinners/PulseLoader';

import { Text } from '$src/components';

import * as styles from './button.css';

type Props = DetailedHTMLProps<ButtonHTMLAttributes<HTMLButtonElement>, HTMLButtonElement> & {
  className?: string;
  buttonType?: 'primary' | 'secondary';
  isLoading?: boolean;
  dataTest?: string;
};

export const Button: FC<Props> = ({
  className,
  children,
  isLoading,
  buttonType = 'primary',
  dataTest,
  ...rest
}) => {
  if (isLoading) {
    <PulseLoader color="white" size="6px" speedMultiplier={0.8} />;
  }

  const isPrimary = buttonType === 'primary';

  return (
    <button
      className={clsx([
        styles.button,
        className,
        isPrimary ? styles.primaryButton : styles.secondaryButton,
      ])}
      data-test={dataTest}
      {...rest}
    >
      <Text textColor={isPrimary ? 'white' : 'black'}>{children}</Text>
    </button>
  );
};
