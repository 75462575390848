import clsx from 'clsx';
import { FC, ReactNode } from 'react';

import { textColors, textStyles } from './text.css';

type Props = {
  textStyle?: keyof typeof textStyles;
  textColor?: keyof typeof textColors;
  className?: string;
  dataTest?: string;
  children: ReactNode;
};

export const Text: FC<Props> = ({ children, textStyle, className, textColor, dataTest }) => (
  <span
    className={clsx([
      className,
      textStyles[textStyle ?? 'light1420'],
      textColors[textColor ?? 'black'],
    ])}
    data-test={dataTest}
  >
    {children}
  </span>
);
