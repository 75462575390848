import clsx from 'clsx';
import type { FC, ReactNode } from 'react';

import * as styles from './link.css';

type Props = {
  href?: string;
  className?: string;
  onClick?: () => void;
  children: ReactNode;
};

// Link can be used as link when href is given or as generic button when onClick is given
export const Link: FC<Props> = ({ children, href, className, onClick }) => {
  return (
    <a className={clsx([styles.link, className])} href={href} onClick={onClick}>
      {children}
    </a>
  );
};
