import clsx from 'clsx';
import type { FC } from 'react';

import * as styles from './close-button.css';

type Props = {
  fillColor: 'white' | 'black';
  onClick: () => void;
  className?: string;
  height?: string;
  width?: string;
};

export const CloseButton: FC<Props> = ({
  fillColor,
  onClick,
  className,
  height = '13px',
  width = '13px',
}) => (
  <button
    aria-label="close-button"
    className={clsx([styles.closeButton, className])}
    onClick={onClick}
  >
    <svg
      width={width}
      height={height}
      viewBox="0 0 12 12"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M7.41524 6.00103L12.002 10.5878L10.5878 12.002L6.00103 7.41524L1.41421 12.0021L0 10.5878L4.58681 6.00103L0 1.41421L1.41421 0L6.00103 4.58681L10.5878 7.62096e-06L12.002 1.41422L7.41524 6.00103Z"
        fill={fillColor}
      />
    </svg>
  </button>
);
