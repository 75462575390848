import type { FC } from 'react';

import { Text } from '$src/components/text';

type Props = { className?: string; text: string; dataTest?: string };

export const Header: FC<Props> = ({ className, text, dataTest }) => {
  return (
    <header className={className} data-test={dataTest}>
      <Text textStyle={'light2028'} textColor={'black'}>
        {text}
      </Text>
    </header>
  );
};
