import type { FC, ReactNode } from 'react';

import * as styles from './section.css';

export const Section: FC<{ dataTest?: string; children: ReactNode }> = ({ children, dataTest }) => {
  return (
    <div className={styles.rootElement} data-test={dataTest}>
      {children}
    </div>
  );
};
