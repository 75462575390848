import { useQueryClient } from '@tanstack/react-query';
import axios, { isAxiosError } from 'axios';
import { useRouter } from 'next/router';
import { ComponentProps } from 'react';

import { AuthProvider, TRACKING_DATA_QUERY_KEY } from '@tracking/data';
import { AuthModalProvider } from '@tracking/widget-ui';

export const Auth = ({ isAuthenticated, children }: ComponentProps<typeof AuthProvider>) => {
  const { query, locale } = useRouter();
  const queryClient = useQueryClient();
  return (
    <AuthProvider isAuthenticated={isAuthenticated}>
      <AuthModalProvider
        formSubmitHandler={async ({ email }) => {
          const response = await axios
            .post('/api/auth', { email, tos_id: query.tosId, locale })
            .catch(error => {
              const errorMessage = isAxiosError(error) && error.response?.data.error;

              throw new Error(errorMessage ? errorMessage : 'An error occured.');
            });
          queryClient.setQueryData([TRACKING_DATA_QUERY_KEY], response.data);
        }}
      >
        {children}
      </AuthModalProvider>
    </AuthProvider>
  );
};
