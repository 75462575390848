import Link from 'next/link';
import { useRouter } from 'next/router';
import type { FC } from 'react';

import { useAnalytics } from '@tracking/data';
import { localeToLocalName, useFormatMessage } from '@tracking/i18n';

import { ExternalLink, Text } from '$src/components';

import * as styles from './footer.css';

export const Footer: FC = () => {
  const { locale, query } = useRouter();
  const formatMessage = useFormatMessage();
  const analytics = useAnalytics();

  return (
    <footer className={styles.footer} data-test="portal-footer">
      <div className={styles.footerContent}>
        <div className={styles.footerLinks}>
          <ExternalLink
            href={'https://ingrid.com/privacy-policy'}
            className={styles.link}
            dataTest="portal-footer-privacy-link"
            onClick={() => analytics.capture('redirect_privacy_policy')}
          >
            <Text textStyle={'regular1620'} textColor={'white'}>
              {formatMessage('PORTAL.FOOTER.PRIVACY')}
            </Text>
          </ExternalLink>
          <ExternalLink
            href={'https://ingrid.com/cookie-policy'}
            dataTest="portal-footer-cookies-link"
            className={styles.link}
            onClick={() => analytics.capture('redirect_cookies')}
          >
            <Text textStyle={'regular1620'} textColor={'white'}>
              {formatMessage('PORTAL.FOOTER.COOKIES')}
            </Text>
          </ExternalLink>
          <Link
            href={{ pathname: '/choose-language', query }}
            as="/choose-language"
            className={styles.link}
            data-test="portal-footer-change-locale-link"
            onClick={() => analytics.capture('choose_language')}
          >
            <Text textStyle={'regular1620'} textColor={'white'}>
              {localeToLocalName.get(locale!)}
            </Text>
          </Link>
        </div>
        <Text textStyle={'regular1620'} textColor={'white'}>
          {formatMessage('PORTAL.FOOTER.COPYRIGHT', { year: new Date().getFullYear() })}
        </Text>
      </div>
    </footer>
  );
};
