import { assignInlineVars } from '@vanilla-extract/dynamic';
import clsx from 'clsx';
import type { FC } from 'react';

import { styles, vars } from './progress-bar.css';

type Props = {
  className?: string;
  progress: number;
};

export const ProgressBar: FC<Props> = ({ className, progress }) => {
  // make sure `progress` is an integer in [0,100] range
  const progressSafe = Math.round(Math.max(0, Math.min(100, progress)));
  return (
    <div className={clsx([styles.baseBar, className])}>
      <div
        className={styles.progressBar}
        style={assignInlineVars({ [vars.progressBarWidth]: `${progressSafe}%` })}
      />
    </div>
  );
};
