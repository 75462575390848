import clsx from 'clsx';
import type { FC, ReactNode } from 'react';

import { ArrowRightwards, Text } from '$src/components';

import * as styles from './go-button.css';

type LinkProps = {
  className?: string;
  href: string;
  linkColor?: 'black' | 'white';
  dataTest?: string;
  onPosthogCapture?: () => void;
  children: ReactNode;
};

export const GoExternalLink: FC<LinkProps> = ({
  className,
  children,
  linkColor = 'black',
  href,
  dataTest,
  onPosthogCapture,
}) => {
  const inversedButtonColor = linkColor === 'black' ? 'white' : 'black';
  return (
    <div className={clsx([styles.buttonContainer, className])}>
      <div>
        <a
          href={href}
          className={clsx([styles.button, styles.buttonColor[linkColor]])}
          target="_blank"
          rel="noreferrer"
          data-test={dataTest}
          onClick={onPosthogCapture}
        >
          <Text textStyle={'bold1620'} textColor={inversedButtonColor}>
            {children}
          </Text>
          <div className={styles.animatedArrow}>
            <ArrowRightwards arrowColor={inversedButtonColor} />
          </div>
        </a>
      </div>
    </div>
  );
};
