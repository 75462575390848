import type { FC } from 'react';

type Props = {
  arrowColor?: 'black' | 'white';
};

export const ArrowRightwards: FC<Props> = ({ arrowColor = 'white' }) => {
  return (
    <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M11.3866 7.00691L7.09367 2.71402L8.50789 1.2998L14.5079 7.29981L15.215 8.00691L14.5079 8.71402L8.50789 14.714L7.09367 13.2998L11.3866 9.00691L0.800781 9.00691V7.00691L11.3866 7.00691Z"
        fill={arrowColor}
      />
    </svg>
  );
};
